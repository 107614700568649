import api from "../axios_service.js";

export async function fetchAtividades(id) {
  const response = await api.get(`/colaborador/atividades/${id}`);
  return response.data.result;
}

export async function fetchAtividadeCliente(id) {
  const response = await api.get(`/colaborador/atividades-colaborador/${id}`);
  return response.data.result;
}

export async function fetchAtividadesClientesHistoricos(atividade_id) {
  const response = await api.get(
    `/colaborador/atividades-clientes-historico/${atividade_id}`
  );
  return response.data.result;
}

export function putAtividadeCliente(id, body) {
  return api.put(`/colaborador/atividades/${id}`, body);
}

export function putAtividadeEditarTempo(id, tempo) {
  return api.put(`/colaborador/atividades-clientes-editar-tempo/${id}`, tempo);
}

export async function postAtividadeAdicionarTempo(tempo) {
  const response = await api.post(
    "/colaborador/atividades-clientes-adicionar-tempo/add",
    tempo
  );
  return response;
}

export function deleteAtividadeTempo(id) {
  return api.delete(`/colaborador/atividades-clientes-tempo/${id}`);
}


